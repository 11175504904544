import { SerializedFarmConfig } from '@pancakeswap/farms'
import { longTokens } from '@pancakeswap/tokens'

const priceHelperLps: SerializedFarmConfig[] = [
  {
    pid: null,
    lpSymbol: 'OEX-USDT LP',
    lpAddress: '0x8d7B52daf21f398b07e544a613960bD5a0c9FE01',
    token: longTokens.oex,
    quoteToken: longTokens.wusdt,
  },
].map((p) => ({ ...p, token: p.token.serialize, quoteToken: p.quoteToken.serialize }))

export default priceHelperLps
