import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const longTokens = {
  wusdt: new ERC20Token(
    ChainId.LONG,
    '0x8Ce4B67b08c147572c463c894Ff5b540FB58C42a',
    18,
    'WUSDT',
    'Wrapped USD Tether',
    'https://openex.network/',
  ),
  oex: new ERC20Token(
    ChainId.LONG,
    '0x6cBFE2BBFcbf90e7b2D0223541c122A13cBAb6a1',
    18,
    'OEX',
    'OpenEX Token',
    'https://openex.network/',
  ),
}
