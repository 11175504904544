import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | LongSwap',
  defaultTitle: 'LongSwap',
  description: 'The leading DEX on OpenEX block chain testnet (LONG) for $OEX.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@LongSwap',
    site: '@LongSwap',
  },
  openGraph: {
    title: '⬡ LongSwap - A next evolution DeFi exchange on OpenEX block chain (LONG)',
    description: 'The most popular AMM on OEX by user count! Earn $OEX through staking!  ',
    images: [{ url: 'https://swap.openex.network/images/hero.png' }],
  },
}
