import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const boatTokens = {
  wboat: new ERC20Token(
    ChainId.BOAT,
    '0x6Edf8aecAA888896385d7fA19D2AA4eaff3C10D8',
    18,
    'WBOAT',
    'Wrapped BOAT',
    'https://come.boats/',
  ),
  fubao: new ERC20Token(
    ChainId.BOAT,
    '0xeAe1722769E6b220A62c193122dF2F9630A3Cb77',
    18,
    'FUBAO',
    'Fubao Panda Token',
    'https://come.boats/',
  ),
  busd: new ERC20Token(
    ChainId.BOAT,
    '0x76e37f363287cCB0Bd511ED284D3924FaeD4a3a0',
    18,
    'BUSD',
    'USD on BOAT',
    'https://come.boats/',
  ),
}
