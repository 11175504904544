const TRUST_TOKENS =
  'https://bafybeido5sm2gpsyz7pqw4qg2eykb2fk3w4ppnxrcoblxrh4u5j2utncqe.ipfs.nftstorage.link/list.json'

export const PANCAKE_EXTENDED = TRUST_TOKENS

export const COINGECKO = TRUST_TOKENS
export const PANCAKE_ETH_DEFAULT = TRUST_TOKENS
export const PANCAKE_ETH_MM = TRUST_TOKENS
export const PANCAKE_BSC_MM = TRUST_TOKENS
export const COINGECKO_ETH = TRUST_TOKENS
export const CMC = TRUST_TOKENS

export const ETH_URLS = [PANCAKE_EXTENDED]
export const BSC_URLS = [PANCAKE_EXTENDED]

// List of official tokens list
export const OFFICIAL_LISTS = [PANCAKE_EXTENDED]

export const UNSUPPORTED_LIST_URLS: string[] = []
export const WARNING_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...BSC_URLS,
  ...ETH_URLS,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
  ...WARNING_LIST_URLS,
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [PANCAKE_EXTENDED]
