import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, YoutubeIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "About",
    items: [
      {
        label: "Contact",
        href: "https://docs.swap.openex.network/contact-us",
      },
      {
        label: "Blog",
        href: "https://blog.swap.openex.network/",
      },
      {
        label: "Community",
        href: "https://docs.swap.openex.network/contact-us/telegram",
      },
      {
        label: "OST",
        href: "https://docs.swap.openex.network/tokenomics",
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.swap.openex.network/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.swap.openex.network/help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://docs.swap.openex.network/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/coreswap",
      },
      {
        label: "Documentation",
        href: "https://docs.swap.openex.network",
      },
      {
        label: "Careers",
        href: "https://docs.swap.openex.network/hiring/become-a-chef",
      },
    ],
  },
];

export const socials = [
  // {
  //   label: "Twitter",
  //   icon: TwitterIcon,
  //   href: "https://twitter.com/coreswap",
  // },
  // {
  //   label: "Telegram",
  //   icon: TelegramIcon,
  //   items: [
  //     {
  //       label: "English",
  //       href: "https://t.me/coreswap",
  //     },
  //     {
  //       label: "中文",
  //       href: "https://t.me/coreswap_cn",
  //     },
  //     {
  //       label: "日本語",
  //       href: "https://t.me/coreswap_jp",
  //     },
  //   ],
  // },
  // {
  //   label: "Reddit",
  //   icon: RedditIcon,
  //   href: "https://reddit.com/r/coreswap",
  // },
  // {
  //   label: "Instagram",
  //   icon: InstagramIcon,
  //   href: "https://instagram.com/coreswap",
  // },
  // {
  //   label: "Github",
  //   icon: GithubIcon,
  //   href: "https://github.com/coreswap",
  // },
  // {
  //   label: "Discord",
  //   icon: DiscordIcon,
  //   href: "https://discord.gg/coreswap",
  // },
  // {
  //   label: "Youtube",
  //   icon: YoutubeIcon,
  //   href: "https://www.youtube.com/@coreswap",
  // },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
