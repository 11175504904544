import { ContextApi } from "@pancakeswap/localization";
import { FooterLinkType } from "../../../components/Footer/types";

export const footerLinks: (t: ContextApi["t"]) => FooterLinkType[] = (t) => [
  {
    label: t("About"),
    items: [
      {
        label: t("Contact"),
        href: "https://docs.swap.openex.network/contact-us",
        isHighlighted: true,
      },
      {
        label: t("Brand"),
        href: "https://docs.swap.openex.network/brand",
      },
      {
        label: t("Blog"),
        href: "https://blog.swap.openex.network/",
      },
      {
        label: t("Community"),
        href: "https://docs.swap.openex.network/contact-us/telegram",
      },
    ],
  },
  {
    label: t("Help"),
    items: [
      {
        label: t("Customer Support"),
        href: "https://docs.swap.openex.network/contact-us/customer-support",
      },
      {
        label: t("Troubleshooting"),
        href: "https://docs.swap.openex.network/help/troubleshooting",
      },
      {
        label: t("Guides"),
        href: "https://docs.swap.openex.network/get-started",
      },
    ],
  },
  {
    label: t("Developers"),
    items: [
      {
        label: "Github",
        href: "https://github.com/coreswap",
      },
      {
        label: t("Documentation"),
        href: "https://docs.swap.openex.network",
      },
      {
        label: t("Careers"),
        href: "https://docs.swap.openex.network/hiring/become-a-chef",
      },
    ],
  },
];
