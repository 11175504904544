import { FixedNumber } from '@ethersproject/bignumber'

export const FIXED_ZERO = FixedNumber.from(0)
export const FIXED_ONE = FixedNumber.from(1)
export const FIXED_TWO = FixedNumber.from(2)

export const FARM_AUCTION_HOSTING_IN_SECONDS = 691200

// MasterChefV2 Address
export const masterChefAddresses = {
  97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
  56: '0xa5f8C5Dbd5F286960b9d90548680aE5ebFf07652',
  1115: '0xc551ef61f4155Fbd804db7bf17F087a77e681E4A', // V2 Address Test
  1116: '0x163bc0B58B13e560a7C0F50157773b67b462C08f',
}

export const nonBSCVaultAddresses = {
  1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
  5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  1115: '0xc551ef61f4155Fbd804db7bf17F087a77e681E4A', // V2 Address Test
  1116: '0x163bc0B58B13e560a7C0F50157773b67b462C08f',
}
