import { vars } from "@pancakeswap/ui/css/vars.css";
import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 1280 240" {...props}>
      <defs />
      <clipPath id="ArtboardFrame">
        <rect height="240" width="1280" x="0" y="0" />
      </clipPath>
      <g clipPath="url(#ArtboardFrame)" id="gem">
        <g id="logo">
          <path
            fill={vars.colors.contrast}
            stroke={vars.colors.contrast}
            id="Logo"
            d="M113.441,0C50.789,0,0,50.79,0,113.443c0,62.654,50.789,113.441,113.441,113.441
	c62.654,0,113.443-50.787,113.443-113.441C226.885,50.79,176.096,0,113.441,0z M157.477,168.762H68.839l7.45-35.566l-14.486,9.933
	l3.519-19.463l15.151-10.43l14.862-70.939h27.671l-10.232,48.71L148.8,66.213l-4.222,20.167l-36.02,24.693l-7.126,33.93H162.4
	L157.477,168.762z"
          />
        </g>
      </g>
      <g id="text">
        <g>
          <text x="280" y="150" fill={vars.colors.contrast} fontSize={120}>
            LongSwap
          </text>
        </g>
      </g>
    </Svg>
  );
};

export default Logo;
