import { ChainId, ERC20Token } from '@pancakeswap/sdk'

export const OEX_CORE = new ERC20Token(
  ChainId.CORE,
  '0xFd843e2eB0E5F7e652fB864477D57510a469b332',
  18,
  'OEX',
  'OpenEX Network Token',
  'https://openex.network/',
)

export const OEX_LONG = new ERC20Token(
  ChainId.LONG,
  '0x6cBFE2BBFcbf90e7b2D0223541c122A13cBAb6a1',
  18,
  'OEX',
  'OpenEX Network Token',
  'https://swap.openex.network/',
)

export const CAKE_MAINNET = new ERC20Token(
  ChainId.LONG,
  '0x6cBFE2BBFcbf90e7b2D0223541c122A13cBAb6a1',
  18,
  'OEX',
  'OpenEX Token',
  'https://swap.openex.network/',
)

export const CAKE_TESTNET = new ERC20Token(
  ChainId.BTCS,
  '0x8F8B6EC12BD998843908Ce41873AE70bb794213D',
  18,
  'OST',
  'LongSwap Token',
  'https://swap.openex.network/',
)

export const USDC_BSC = new ERC20Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
  6,
  'USDC',
  'USD Coin',
)

export const USDC_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDT_BSC = new ERC20Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new ERC20Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_TESTNET = new ERC20Token(
  ChainId.BSC_TESTNET,
  '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_GOERLI = new ERC20Token(
  ChainId.GOERLI,
  '0xb809b9B2dc5e93CB863176Ea2D565425B03c0540',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

// 与一般的USDT不同，这是18精度的
export const USDT_LONG = new ERC20Token(
  ChainId.LONG,
  '0x8Ce4B67b08c147572c463c894Ff5b540FB58C42a',
  18, // 注意这里是18！！
  'WUSDT',
  'Wappted Tether USD',
  'https://tether.to/',
)

export const USDT_CORE = new ERC20Token(
  ChainId.CORE,
  '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
  6, // 注意这里是6
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

// USDC Core: 0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9
export const USDC_CORE = new ERC20Token(
  ChainId.CORE,
  '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
  6, // 注意这里是6
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const BUSD_BTCS = new ERC20Token(
  ChainId.BTCS,
  '0x900Ee9152DD04c5117134C244A53BFFe7342ffC9',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)

export const BUSD_BOAT = new ERC20Token(
  ChainId.BOAT,
  '0x76e37f363287cCB0Bd511ED284D3924FaeD4a3a0',
  18,
  'BUSD',
  'USD on BOAT',
  'https://come.boats/',
)

export const WBOAT_BOAT = new ERC20Token(
  ChainId.BOAT,
  '0x6Edf8aecAA888896385d7fA19D2AA4eaff3C10D8',
  18,
  'WBOAT',
  'Wrapped BOAT',
  'https://come.boats/',
)

export const BUSD: Record<ChainId, ERC20Token> = {
  [ChainId.ETHEREUM]: BUSD_ETH,
  [ChainId.GOERLI]: BUSD_GOERLI,
  [ChainId.BSC]: BUSD_BSC,
  [ChainId.BSC_TESTNET]: BUSD_TESTNET,
  [ChainId.LONG]: USDT_LONG,
  [ChainId.QILIN]: USDT_LONG,
  [ChainId.BTCS]: BUSD_BTCS,
  [ChainId.CORE]: USDT_CORE,
  [ChainId.BOAT]: BUSD_BTCS,
}

export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.LONG]: OEX_LONG,
  [ChainId.CORE]: OEX_CORE,
  [ChainId.BTCS]: CAKE_MAINNET,
  [ChainId.BOAT]: WBOAT_BOAT,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.GOERLI]: USDC_GOERLI,
  [ChainId.CORE]: USDC_CORE,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.LONG]: USDT_LONG,
  [ChainId.CORE]: USDT_CORE,
  [ChainId.BTCS]: USDT_LONG,
}

export const WBTC_ETH = new ERC20Token(
  ChainId.ETHEREUM,
  '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
  8,
  'WBTC',
  'Wrapped BTC',
)
